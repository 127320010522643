<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item label="名称：" prop="name">
          <el-input
            placeholder="请输入名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级分类：" prop="department_id">
          <el-cascader
            class="form-inp"
            v-model="formLabelAlign.department_id"
            :options="bepartment_Data"
            :props="{
              checkStrictly: true,
              label: 'name',
              children: 'items',
              value: 'id',
            }"
            @change="dataChange"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { DangercatesaveInfo, Dangercatesave } from "../../assets/request/api";
import Bepartment from "../../components/Bepartment";
export default {
  name: "OrganizationalReadct",
  components: {
    Bepartment,
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  data() {
    return {
      type_name: "保存",
      //要修改的id
      id: "",
      formLabelAlign: {
        type: "",
        department_id: "",
        name: "",
      },
      bepartment_Data: [],
    };
  },
  methods: {
    way_show(type, id) {
      this.formLabelAlign.type = type;
      let froms = {
        type,
      };
      if (id) froms.id = id;
      DangercatesaveInfo(froms).then((res) => {
        let { info, status_list, categorys } = res.data;
        this.status_list = status_list;
        this.bepartment_Data = categorys;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, pid } = info;
          formLabelAlign.name = name;
          formLabelAlign.id = id;
          formLabelAlign.department_id = pid;
        }
      });
    },
    dataChange(event) {
      let events = event[event.length - 1];
      console.log(events);
      this.formLabelAlign.department_id = events;
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formLabelAlign = JSON.parse(JSON.stringify(this.formLabelAlign))
          if (formLabelAlign.department_id == "") {
            formLabelAlign.pid = 0;
          } else {
            formLabelAlign.pid = formLabelAlign.department_id;
          }

          delete formLabelAlign.department_id;
          Dangercatesave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_list");
              setTimeout(function () {
                that.$emit("update:refbool", false);
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
.reminder {
  color: #409eff;
}
</style>