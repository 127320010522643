<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">风险点分类</p>
        <div class="segmentation"></div>
      </div>
      <div class="tree-form" style="margin-top: 20px;">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(item, index) in tab_Data" :key="index" :label="item.name" :name="index + ''">
            <div class="cx-sty">
              <el-button type="primary" @click="on_pop_addplus(item.type)" icon="el-icon-plus">新建</el-button>

              <div v-show="activeName == 0" class="dif-alcen">
                <div style="width: 60px">查询：</div>
                <el-input placeholder="请输入风险点完成名称" v-model="queryName">
                </el-input>
              </div>
            </div>

            <el-tree :ref="`tree${index}`" :filter-node-method="filterNode" :data="item.data" :props="{
              label: 'name',
              children: 'items',
            }" accordion>
              <div class="custom-tree-node" slot-scope="{ node, data }">
                <el-link type="primary">{{ node.label }}</el-link>
                <!-- <p>{{ node.label }}</p> -->
                <p class="operation">
                  <i style="margin-right: 10px" v-if="data.id !== 0" @click.stop="() => on_deletes(data.id)"
                    class="el-icon-delete isty"></i>
                  <i v-if="data.id !== 0" @click="() => on_pop_addplus(item.type, data.id)" class="el-icon-edit isty"></i>
                </p>
              </div>
            </el-tree>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center @close="way_cloce">
      <Riskpointclassifyreadct v-if="dialog_show" ref="Riskpointclassifyreadct" :refbool.sync="dialog_show"
        @way_list="way_list" />
    </el-dialog>
  </div>
</template>

<script>
import Riskpointclassifyreadct from "../../components/view_redact/risk_pointclassifyreadct.vue";
import { Dangercateindex, Dangercatedelete } from "../../assets/request/api";
export default {
  name: "organizational_structure",
  components: { Riskpointclassifyreadct },
  data() {
    return {
      queryName: "",
      title: "",
      dialog_show: false,
      list_Data: [],
      activeName: "0",
      tab_Data: [
        { name: "风险点", data: [], type: "1" },
        { name: "风险源", data: [], type: "2" },
        { name: "重点人群", data: [], type: "3" },
        { name: "隐患分类", data: [], type: "4" },
        { name: "事件分类", data: [], type: "5" },
        { name: "安全宣教分类", data: [], type: "6" },
      ],
    };
  },
  created() {
    this.way_list(this.tab_Data[0]);
  },
  watch: {
    queryName(val) {
      this.$refs.tree0[0].filter(val);
    },
  },
  methods: {
    way_list(item) {
      Dangercateindex({ type: item.type }).then((res) => {
        let { data } = res.data;
        item.data = data;
      });
    },

    on_deletes(id) {
      let that = this;
      this.$confirm("是否确定删除节点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          Dangercatedelete({ id: id }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              that.way_list();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    on_pop_addplus(type, id) {
      this.dialog_show = true;
      this.title = id ? "修改" : "新增";
      this.$nextTick(function () {
        this.$refs.Riskpointclassifyreadct.way_show(type, id);
      });
    },
    handleClick(tab, event) {
      let item = this.tab_Data[tab.paneName];
      if (item.data.length == 0) {
        this.way_list(item);
      }
    },
    way_cloce() {
      let { tab_Data, activeName } = this;
      this.way_list(tab_Data[activeName]);
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #0788ff;
  font-weight: bold;
  padding-bottom: 10px;
}

.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0788ff;
}

.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}

.tree-form {
  background: #ffffff;
  padding: 20px;
}

.custom-tree-node {
  display: flex;
  justify-content: space-between;
}

s .operation {
  .isty {
    padding-right: 15px;
  }
}

.custom-tree-node {
  width: 100%;
  padding-bottom: 4px;
  border-bottom: 1px solid #ebecec;
}

.el-tree-node {
  margin: 3px 0;
}

.cx-sty {
  display: flex;
  justify-content: space-between;
}

.dif-alcen {
  display: flex;
  align-items: center;
}
</style>
